import React, { useEffect, useState } from "react";
import { levelTypes } from "../../Container/SelectConfig";
import V2rayNapsternetV from "./V2rayNapsternetV";
import V2rayV2rayNg from "./V2rayV2rayNg";

export default function V2rayMac({ setShowVideo, setSubBtnsStatus, setLevel }) {
  const [isShowBtn, setIsShowBtn] = useState(false);
  const [activeSoftware, setActiveSoftware] = useState("");
  const clickhandler = () => {
    setIsShowBtn((prevshow) => {
      return !prevshow;
    });
  };

  useEffect(() => {
    if (isShowBtn) {
      setLevel(levelTypes.softwareSelect);
    }
    setSubBtnsStatus((prevStatuses) => {
      return { ...prevStatuses, Mac: isShowBtn };
    });
  }, [isShowBtn]);
  return (
    <>
      <div className="d-flex justify-content-center">
        <button
          onClick={clickhandler}
          type="button"
          className={`${
            isShowBtn
              ? " box-click mt-2 btn w-75 py-3 shadow-lg"
              : "box mt-2 btn w-75 py-3   "
          }  `}
        >
          Mac
        </button>
      </div>
      {isShowBtn && (
        <>
          <V2rayV2rayNg
            isActiveBtn={activeSoftware === "v2rayNg"}
            setShowVideo={setShowVideo}
            setActiveSoftware={setActiveSoftware}
            videoSrc="/image/minion.mp4"
          />
          <V2rayNapsternetV
            isActiveBtn={activeSoftware === "napsternetV"}
            setShowVideo={setShowVideo}
            setActiveSoftware={setActiveSoftware}
            videoSrc="/image/minion.mp4"
          />
        </>
      )}
    </>
  );
}

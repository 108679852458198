import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage/HomePage";
import RulesPage from "./Pages/RulesPage/RulesPage";
import NotificationsPage from "./Pages/NotificationsPage/NotificationsPage";
import EducationsPage from "./Pages/EducationsPage/EducationsPage";
import "./theme/App.css";
import AboutUs from "./Pages/AboutUs";
import CotactUs from "./Pages/CotactUs";
import HowToShop from "./Pages/HowToShop";
import HowToDownload from "./Pages/HowToDownload";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/rules" element={<RulesPage />} />
          <Route path="/notif" element={<NotificationsPage />} />
          <Route path="/education" element={<EducationsPage />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<CotactUs />} />
          <Route path="/howtoshop" element={<HowToShop />} />
          <Route path="/howtodownload" element={<HowToDownload />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

import NavBar from "../../Components/Navbar/NavBar";
import ContainerFluid from "../../Components/Container/ContainerFluid";
import Footer from "../../Components/Footer/Footer";

export default function HomePage() {
  return (
    <>
      <NavBar />
      <ContainerFluid />
      <Footer />
    </>
  );
}

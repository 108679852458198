import React, { useEffect, useState } from "react";
import { levelTypes } from "../../Container/SelectConfig";
import SshTunnelAndroid from "./SshTunnelAndroid";
import SshTunnelIos from "./SshTunnelIos";
import SshTunnelMac from "./SshTunnelMac";
import SshTunnelWindows from "./SshTunnelWindows";
import SshTunnelLinux from "./SshTunnelLinux";

export default function SshTunnelItems({ setLevel }) {
  const [subBtnsStatus, setSubBtnsStatus] = useState({
    android: false,
    ios: false,
    windows: false,
    mac: false,
    linux: false,
  });

  useEffect(() => {
    if (Object.values(subBtnsStatus).every((status) => !status)) {
      console.log("hey");
      setLevel(levelTypes.osSelect);
    }
    console.log(Object.values(subBtnsStatus).every((status) => !status));
  }, [subBtnsStatus]);

  return (
    <>
      <SshTunnelAndroid
        setSubBtnsStatus={setSubBtnsStatus}
        setLevel={setLevel}
      />
      <SshTunnelIos setSubBtnsStatus={setSubBtnsStatus} setLevel={setLevel} />
      <SshTunnelWindows
        setSubBtnsStatus={setSubBtnsStatus}
        setLevel={setLevel}
      />
      <SshTunnelMac setSubBtnsStatus={setSubBtnsStatus} setLevel={setLevel} />
      <SshTunnelLinux setSubBtnsStatus={setSubBtnsStatus} setLevel={setLevel} />
    </>
  );
}

import React from 'react'
import Footer from '../Components/Footer/Footer'
import NavBar from '../Components/Navbar/NavBar'

export default function HowToShop() {
  return (
    <>
    <NavBar />
    <div class="container">
        <div className="mt-5 d-flex justify-content-center align-items-center fw-bolder  ">
          <h2 className="fw-bolder text-white">نحوه ی خرید</h2>
        </div>
      <div class="row mt-4 mb-4">
     
          <p className="lh-lg custom-rule-p p-3 mt-5 text-white">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
            استفاده از طراحان گرافیک است ، چاپگرها و متون بلکه روزنامه و مجله
            در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد
            نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد،
            کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
            جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای
            طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان
            فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری
            موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد
            نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل
            دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
          </p>
  
      </div>
    </div>

    <Footer />
  </>  )
}

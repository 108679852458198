import React, { useState } from "react";
import DownloadButtonSelectConfig from "./DownloadButtonSelectConfig";
import DownloadTextSelectConfig from "./DownloadTextSelectConfig";

export const levelTypes = {
  configSelect: "configSelect",
  osSelect: "osSelect",
  softwareSelect: "softwareSelect",
};

export default function SelectDownloadConfig() {
  const [level, setLevel] = useState(levelTypes.configSelect);

  return (
    <div className="col">
      <DownloadTextSelectConfig level={level} />
      <DownloadButtonSelectConfig setLevel={setLevel} />
    </div>
  );
}

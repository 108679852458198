import React from "react";

export default function V2rayV2rayNg({ isActiveBtn, setActiveSoftware }) {
  const clickHandler = () => {
    setActiveSoftware("v2rayNg");
  };
  return (
    <div className="d-flex justify-content-center">
      <a
        onClick={clickHandler}
        // type="button"
        href="https://www.google.com"
        target='_blank'
        className={`${isActiveBtn ? "box-click" : "box"} mt-2 btn w-50 py-3`}
      >
        V2rayNg
      </a>
    </div>
  );
}

import React from "react";

export default function V2rayNapsternetV({ isActiveBtn, setActiveSoftware }) {
  const clickHandler = () => {
    setActiveSoftware("napsternetV");
  };

  return (
    <div className="d-flex justify-content-center">
      <button
        onClick={clickHandler}
        type="button"
        className={`${isActiveBtn ? "box-click" : "box"} mt-2 btn w-50 py-3`}
      >
        NapsternetV
      </button>
    </div>
  );
}

import React from "react";
import LiNavbar from "./LiNavbar";

export default function UlNavbar() {
  const UlNavbarArray = [
    { title: "خانه", href: "/" },
    { title: "اطلاعیه ها", href: "/notif" },
    { title: "آموزش ها", href: "/education" },
    { title: "قوانین", href: "/rules" },
    { title: "تماس با ما", href: "/contactus" },
    { title: "درباره ی ما ", href: "/aboutus" },
    { title: "نحوه خرید", href: "/howtoshop" },
    { title: "نحوه دانلود", href: "/howtodownload" },
  ];
  const liNavbar = UlNavbarArray.map((LiItem) => {
    return <LiNavbar liItem={LiItem} />;
  });
  return <ul className="navbar-nav me-auto mb-2 mb-lg-0">{liNavbar}</ul>;
}

import React from "react";

export default function SshTunnelNapsternetV({
  isActiveBtn,
  setShowVideo,
  setActiveSoftware,
  videoSrc
}) {
  const clickHandler = () => {
    setActiveSoftware("napsternetV");
    setShowVideo((prev) => {
      return { src: videoSrc, isshow: true };
    });
  };

  return (
    <div className="d-flex justify-content-center">
      <button
        onClick={clickHandler}
        type="button"
        className={`${isActiveBtn ? "box-click" : "box"} mt-2 btn w-50 py-3`}
      >
        NapsternetV
      </button>
    </div>
  );
}

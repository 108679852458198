import React, { useEffect, useState } from "react";
import { levelTypes } from "../../Container/SelectConfig";
import SshTunnelNapsternetV from "./SshTunnelNapsternetV";
import SshTunnelV2rayNg from "./SshTunnelV2rayNg";

export default function SshTunnelLinux({ setSubBtnsStatus, setLevel }) {
  const [isShowBtn, setIsShowBtn] = useState(false);
  const [activeSoftware, setActiveSoftware] = useState("");
  const clickhandler = () => {
    setIsShowBtn((prevshow) => {
      return !prevshow;
    });
  };

  useEffect(() => {
    if (isShowBtn) {
      setLevel(levelTypes.softwareSelect);
    }
    setSubBtnsStatus((prevStatuses) => {
      return { ...prevStatuses, Linux: isShowBtn };
    });
  }, [isShowBtn]);
  return (
    <>
      <div className="d-flex justify-content-center">
        <button
          onClick={clickhandler}
          type="button"
          className={`${
            isShowBtn
              ? " box-click mt-2 btn w-75 py-3 shadow-lg"
              : "box mt-2 btn w-75 py-3   "
          }  `}
        >
          Linux
        </button>
      </div>
      {isShowBtn && (
        <>
          <SshTunnelV2rayNg
            isActiveBtn={activeSoftware === "v2rayNg"}
            setActiveSoftware={setActiveSoftware}
          />
          <SshTunnelNapsternetV
            isActiveBtn={activeSoftware === "napsternetV"}
            setActiveSoftware={setActiveSoftware}
          />
        </>
      )}
    </>
  );
}

import React from "react";

export default function V2rayV2rayNg({
  isActiveBtn,
  setShowVideo,
  setActiveSoftware,
  videoSrc,
}) {
  const clickHandler = () => {
    setActiveSoftware("v2rayNg");
    // setShowVideo((prev) => {
    //   return { src: "/image/mov_bbb.mp4", isshow: true };
    // });
    setShowVideo((prev) => {
      return { src: videoSrc, isshow: true };
    });
  };
  return (
    <div className="d-flex justify-content-center">
      <button
        onClick={clickHandler}
        type="button"
        className={`${isActiveBtn ? "box-click" : "box"} mt-2 btn w-50 py-3`}
      >
        V2rayNg
      </button>
    </div>
  );
}

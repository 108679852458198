import React, { useEffect, useState } from "react";
import SshTunnelBtn from "./SshTunnelBtn";
import SshTunnelItems from "./SshTunnelItems";

export default function SshTunnel({ setLevel, setShowVideo, setConfigBtnsStatus }) {
  const [isShowBtn, setIsShowBtn] = useState(false);

  useEffect(() => {
    setConfigBtnsStatus((prevStatuses) => {
      return { ...prevStatuses, v2ray: isShowBtn };
    });
  }, [isShowBtn]);

  return (
    <div class="col-sm">
    <SshTunnelBtn isShowBtn={isShowBtn} setIsShowBtn={setIsShowBtn} />
    {isShowBtn && (
      <SshTunnelItems setLevel={setLevel} setShowVideo={setShowVideo} />
    )}
  </div>
  );
}

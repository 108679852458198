import React from "react";

export default function SshTunnelV2rayNg({
  isActiveBtn,
  setActiveSoftware,
}) {
  const clickHandler = () => {
    setActiveSoftware("v2rayNg");
  };
  return (
    <div className="d-flex justify-content-center">
      <button
        onClick={clickHandler}
        type="button"
        className={`${isActiveBtn ? "box-click" : "box"} mt-2 btn w-50 py-3`}
      >
        V2rayNg
      </button>
    </div>
  );
}

import React from "react";

export default function SshTunnelBtn({ setIsShowBtn, isShowBtn }) {
  const clickhandler = () => {
    setIsShowBtn((prevshow) => {
      return !prevshow;
    });
  };

  return (
    <button
      onClick={clickhandler}
      type="button"
      className={`${
        isShowBtn
          ? "box-click mt-2 btn w-100 py-3 rounded-pill shadow-lg"
          : "box mt-2 btn w-100 py-3 rounded-pill "
      }  `}
    >
      Ssh Tunnel
    </button>
  );
}

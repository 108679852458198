import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import DownloadContainer from "../Components/Download/DownloadContainer";

export default function HowToDownload() {
  return (
    <>
      <NavBar />
      <div className="container-fluid container-custom">
        <DownloadContainer />
      </div>
      <Footer />
    </>
  );
}

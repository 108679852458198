import SelectDownloadConfig from "./SelectDownloadConfig";

export default function DownloadContainer() {
  return (
    <div className="container mx-auto">
      <div className="row ">
        <SelectDownloadConfig />
      </div>
    </div>
  );
}
